<template>
    <div class="card box">
        <div class="widget-social">
            <div v-if="isLoading">
                <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="4" fill="var(--red-600)" animationDuration="1s" />
            </div>
            <div v-if="this.$store.getters.getPageData.editMode" class="menu-overlay">
                <Button icon="pi pi-bars" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
                <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü" @click="toggle"></Button>
                <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
            </div>
            <div v-if="entryDialog === true">
                <dialog-io-node
                    v-bind:showDialog="entryDialog"
                    :dialogObj="ioObj"
                    v-bind:dialogData="ioDialogData"
                    @entrySave="
                        ($value) => {
                            changeVal($value);
                        }
                    "
                    @entryAbort="hideDialog()"
                ></dialog-io-node>
            </div>
            <div v-if="!isLoading">
                <div class="flex justify-content-between align-items-center p-1">
                    <div class="social-icon">
                        <i :class="icon + ' fg-lightAmber fs-xxlarge'"></i>
                    </div>
                    <div class="info flex flex-column">
                        <!-- <span class="value">{{ value }}</span> -->
                        <transition name="valchange" mode="out-in">
                            <h1 :key="getModuleValue().value" class="inline-block mb-0 mt-0 cursor-pointer" @click="showChart('value1')">
                                <!-- <span class="text-lg fg-lightBlue">{{ currentSetpoint.value + currentSetpoint.unit }}</span> -->
                                {{ value.value }}<span class="text-lg">{{ unit }}</span>
                            </h1>
                        </transition>
                        <span class="subtext mt-2">{{ getModuleValue().label }}</span>
                    </div>
                </div>

                <div class="stats flex justify-content-between mt-3">
                    <div class="left flex flex-column">
                        <span class="title fg-darkGray">Live</span>
                        <!-- <Chart v-show="loading" ref="storeA" type="line" :data="storeAData" :options="storeOptions" responsive="true"></Chart> -->
                        <div class="charti">
                            <apexchart width="100%" height="70%" type="area" :options="options2" :series="series2" @click="showChart('value1')"></apexchart>
                        </div>
                        <div class="flex">
                            <div class="flex-1 align-items-center justify-content-center fg-darkBlue">
                                <small>MIN</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-olive">
                                <small>AVG</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-darkAmber">
                                <small>MAX</small>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex-1 align-items-center justify-content-center fg-darkBlue">
                                <small>{{ valueLiveMin }}</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-olive">
                                <small>{{ valueLiveAvg }}</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-darkAmber">
                                <small>{{ valueLiveMax }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="right flex flex-column">
                        <span class="title fg-darkGray">24-Stunden</span>
                        <div v-if="chart24Loading" class="mt-3">
                            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="4" fill="var(--red-600)" animationDuration="1s" />
                        </div>
                        <div v-show="!chart24Loading" class="charti">
                            <!-- <Chart v-show="loading" ref="storeB" type="line" :data="storeBData" :options="storeOptions" responsive="true"></Chart> -->
                            <apexchart width="100%" height="70%" type="area" :options="options" :series="series" @click="showChart('value1')"></apexchart>
                        </div>
                        <div class="flex">
                            <div class="flex-1 align-items-center justify-content-center fg-darkBlue">
                                <small>MIN</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-olive">
                                <small>AVG</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-darkAmber">
                                <small>MAX</small>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex-1 align-items-center justify-content-center fg-darkBlue">
                                <small>{{ valueMin }}</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-olive">
                                <small>{{ valueAvg }}</small>
                            </div>
                            <div class="flex-1 align-items-center justify-content-center fg-darkAmber">
                                <small>{{ valueMax }}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="chartDialog === true">
                    <dialog-chart v-bind:showDialog="chartDialog" :content="this.nodeEntry" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                        @entrySave="
                            ($value) => {
                                changeVal($value);
                            }
                        " @entryAbort="hideValveDialog()"></dialog-chart>
                </div>

                <dialog-chart-single :icon="getModuleValue().icon" :node="getModuleValue()" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { mapGetters, mapActions } from 'vuex';
// import { combineRedisHash } from "@/helpers";
import SocketioService from '@/services/socketioService.js';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
    name: 'dashboardWeatherBox',
    setup() {},
    components: {
        dialogChartSingle,
    },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        readyLayout() {
            this.watchLayout();
        },
        value() {
            if (this.loading === false) {
                let data = [...this.storeAData.datasets[0].data];
                let data2 = [...this.series2[0].data];
                data.push(this.getModuleValue().value);
                data.shift();
                data2.push(this.getModuleValue().value);
                data2.shift();
                this.series2 = [{
                    data: data2
                }]

                this.valueLiveMin = Math.min(...data).toFixed(2);
                this.valueLiveMax = Math.max(...data).toFixed(2);
                this.valueLiveAvg = data.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
                this.storeAData.datasets[0].data = data;
                // this.$refs.storeA.chart.update();
            }
            if (this.loading === false) {
                // let dt = new Date(Date.now());
                // let dth = dt.getHours();
                // let dt24h = this.chart24Timestamp.getHours();
                // if (dth !== dt24h) {
                //     console.log(dth, dt24h);
                //     this.getChart();
                // }
            }
        },
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            getNodeWidget: 'getNodeWidget',
            getOneDepartment: 'types/getOneDepartment',
            getOneCategory: 'types/getOneCategory',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
            getSynoptoCode: 'types/getSynoptoCode',
            isMaster: 'auth/isMaster',
        }),
        isLoading: function() {
            if (this.getModuleValue.value === store.getters.getNoval || this.loading) return true;
            return false;
        },
        moduleHash: function () {
            if (this.content.data !== null) {
                if (this.content.data.id !== null) {
                    return `${this.content.data.id}`
                }
            }
            return null;
        },
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else {
                return '???';
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        label: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.metadata.name;
            } else {
                return this.content.data.label;
            }
        },
        department: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.metadata.department;
            } else {
                return this.content.data.department;
            }
        },
        // value: function () {
        //     if (this.getModuleValue() !== undefined) {
        //         if (this.getModuleValue().value === '🤷🏼‍♂️') {
        //             this.loadingState(true);
        //         } else {
        //             this.loadingState(false);
        //         }
        //         return this.getModuleValue().value;
        //     } else {
        //         return this.content.data.value;
        //     }
        // },
        // unit: function () {
        //     if (this.nodeEntry !== undefined) {
        //         if (this.nodeEntry.value.unit === 'boolean') {
        //             return '';
        //         }
        //         return this.nodeEntry.value.unit;
        //     } else {
        //         return this.content.data.unit;
        //     }
        // },
        timestamp: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.timestamp;
            } else {
                return this.content.data.timestamp;
            }
        },
        _id: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry._id;
            } else {
                return this.content.data._id;
            }
        },
        icon: function () {
            if (this.nodeEntry !== undefined) {
                return this.getModuleValue().icon;
            } else {
                return 'mif-blocked';
            }
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
        value: function () {
            return this.parseValue(this.getModuleValue());
        },
        unit: function () {
            return this.value.unitStr;
        },
    },
    data() {
        return {
            loading: false,
            chartDialog: false,
            chart24Timestamp: 0,
            showChart1: false,
            chart24Loading: false,
            values24: [],
            valueMin: 0,
            valueMax: 0,
            valueAvg: 0,
            valueLiveMin: 0,
            valueLiveMax: 0,
            valueLiveAvg: 0,
            dateTimeSelectStart: null,
            dateTimeSelectEnd: null,
            dateTimeFormat: null,
            entryDialog: false,
            ioDialogData: {},
            ioObj: {},
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            options: {
                chart: {
                    id: 'daily',
                    offsetX: 0,
                    toolbar: {
                        autoSelected: "pan",
                        show: false
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                colors: ['rgba(255, 255, 0, 0.9)'],
                stroke: {
                    width: 2,
                    curve: 'smooth',
                },
                grid: {
                    show: false,
                    borderColor: ['rgba(255, 255, 0, 0.1)'],
                    clipMarkers: false,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 10
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    gradient: {
                        enabled: false,
                        opacityFrom: 0.9,
                        opacityTo: 0.9
                    }
                },
                markers: {
                    size: 2,
                    colors: ["#CBFF31"],
                    strokeColor: "#CBFF31",
                    strokeWidth: 1
                },
                tooltip: {
                    enabled: true,
                    theme: "dark",
                    style: {
                        fontSize: '9px',
                    },
                    fixed: {
                        enabled: true,
                        position: 'topCenter',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return (
                            '<div class="box">' +
                            "<span><small>" +
                            w.globals.categoryLabels[dataPointIndex] +
                            ":</small><br/>" +
                            series[seriesIndex][dataPointIndex] +
                            "</span>" +
                            "</div>"
                        );
                    },
                },
                responsive: [
                    {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                        bar: {
                            horizontal: false
                        }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                    }
                ],
                xaxis: {
                    show: false,
                    categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                        formatter: undefined,
                        offsetY: 0,
                        style: {
                            fontSize: 9,
                            fontFamily: 0,
                        },
                    },
                    crosshairs: {
                        show: true,
                        width: 1,
                        position: 'back',
                        opacity: 0.9,        
                        stroke: {
                            color: '#b6b6b6',
                            width: 1,
                            dashArray: 0,
                        },
                        fill: {
                            type: 'solid',
                            color: '#B1B9C4',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    
                },
                yaxis: {
                    show: true,
                    opposite: false,
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 30,
                        offsetX: -10,
                        style: {
                            fontSize: '8px'
                        }
                    },
                    axisBorder: {
                        show: false,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: false,
                        borderType: 'solid',
                        color: '#78909C',
                        width: 6,
                        offsetX: 0,
                        offsetY: 0
                    },
                }
            },
            options2: null,
            series: [
                {
                    name: '',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            ],
            series2: [
                {
                    name: 'Live-Ansicht',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            ],
            items: [
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
            storeADiff: 0,
            storeAData: {
                labels: ['.1', '.2', '.3', '.4', '.5', '.6', '.7', '.8', '.9', '.10'],
                datasets: [
                    {
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        borderColor: ['#CBFF31'],
                        backgroundColor: ['rgba(255, 255, 0, 0.1)'],
                        borderWidth: 2,
                        fill: true,
                        tension: 0.4,
                    },
                ],
            },
            storeBData: {
                labels: ['.1', '.2', '.3', '.4', '.5', '.6', '.7', '.8', '.9', '.10'],
                datasets: [
                    {
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        borderColor: ['#CBFF31'],
                        backgroundColor: ['rgba(255, 255, 0, 0.8)'],
                        borderWidth: 2,
                        fill: true,
                        tension: 0.4,
                    },
                ],
            },
            storeOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                responsive: true,
                aspectRatio: 4,
                scales: {
                    y: {
                        display: true,
                    },
                    x: {
                        display: false,
                    },

                },
                tooltips: {
                    enabled: true,
                },
                elements: {
                    point: {
                        radius: 2,
                    },
                },
                animation: {
                    duration: 1,
                },
            },
        };
    },
    created() {
        this.loading = true;
        this.options2 = JSON.parse(JSON.stringify(this.options));
        this.options2.colors = ['rgba(255, 255, 0, 0.3)'],
        this.options2.chart.id = 'live';
        this.options2.fill.gradient.opacityFrom = 0.3;
        this.options2.fill.gradient.opacityTo = 0.1;
        console.log(this.options);
        console.log(this.options2);
    },
    mounted() {
        this.getChart();
        this.loadNodeOnce(this.moduleHash).then((response) => {
            if (response) {
                this.loading = false;
            }
                // const moduleHash = `${module.source.server}@${module.source.identifier}.*`;
                // SocketioService.getRedisNodesParam(moduleHash, (err, response) => {
                //     if (!err && response) {
                //         if (Array.isArray(response) && response.length > 0) {
                //             this.setNodesFull(response);
                //         }
                //     }
                // }).then(() => { 
                //     if (this.editMode) console.log(`[ENATWIDGET] ${this.moduleHash} : component ready!`);
                //     this.setModuleFetched(this.moduleHash);
                //     this.loading = false;
                // });
            });
        // if (this.getNode(this.moduleHash).value === store.getters.getNoval || this.getNode(this.moduleHash).value === null) {
            
        // } else {
        //     this.loading = false;
        // }
    },
    methods: {
        ...mapActions({
            loadNode: 'opcua/loadNode',
            setNodesFull: 'opcua/setNodesFull',
            loadNodeOnce: 'opcua/loadNodeOnce',
        }),
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        watchLayout() {
            if (this.readyLayout) {
                // this.getNodes();
            }
        },
        getModuleValue() {
            return this.getNode(this.moduleHash);
        },
        getModuleValueStr() {
            const node = this.getNode(this.moduleHash);
            if (typeof node.unit === 'string' && node.unit.length > 0) {
                return `${node.value}${node.unit}`;
            }
            return `${node.value}`;
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.getParameterStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleChartDialog() {
            const ioData = this.content;
            const _obj = JSON.parse(JSON.stringify(ioData));
            this.ioDialogData = _obj;
            this.chartDialog = true;
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            // console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            // this.ioDialogData = { ...ioData };
            // this.getUserList();
            const _obj = JSON.parse(JSON.stringify(ioData));
            this.ioObj = store.getters.getValue(_obj.data.id);
            // const _obj = { ...ioData };
            this.ioDialogData = _obj;
            // this.selectedKey[_obj.data.id] = true;

            console.log(this.nodes);

            for (let node of this.nodes) {
                if (node.key === this.ioObj.metadata.category) {
                    this.expandNode(node);
                }
            }

            // this.expandedKeys[this.nodes[0].key] = true;

            this.entryDialog = true;
            // this.intval_Value = setInterval(this.updateOpenValue, 1000);
        },
        saveDialog() {
            this.submitted = true;

            if (this.ioDialogData.i) {
                // let _index = this.findIndexById(this.ioDialogData._id);
                // if (_index >= 0) {
                //   this.system_user[_index] = this.ioDialogData;
                //   // SocketioService.setUserList(this.system_user[_index]);
                // } else {
                //   // SocketioService.setUserList(this.ioDialogData);
                // }
                // this.content = {...this.ioDialogData};
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        // getChart() {
        //     const node = this.moduleHash;
        //     this.chart24Loading = true;
        //     let dateStart = new Date();
        //     let dateEnd = new Date();
        //     dateStart.setTime(this.dateTimeSelectStart);
        //     dateEnd.setTime(this.dateTimeSelectEnd);
        //     this.chartOptions.xaxis.min = dateStart;
        //     this.chartOptions.xaxis.max = dateEnd;

        //     const request = {
        //         hash: combineRedisHash(node.server, node.identifier),
        //         startTime: dateStart,
        //         endTime: dateEnd,
        //                 format: this.dateTimeFormat
        //         // startTime: dateDayAgo,
        //         // endTime: dateNow
        //     }
        //     this.loading = true;
        //     SocketioService.getChart(request, (err, response) => {
        //         if (!err && response !== null) {
        //         // this.io_data = [ ...response ];
        //         console.log(response);
        //         this.chartOptions.xaxis.categories = [];
        //         this.chartSeries[0].data = [];
        //         response.forEach((entry) => {
        //             const newObj = JSON.parse(JSON.stringify(this.node));
        //             newObj.value = entry._value;
        //             if (typeof newObj.value === 'boolean') {
        //             newObj.unit = "";
        //             if (newObj.value === true) newObj.value = 1;
        //             else newObj.value = 0;
        //             }
        //             if (newObj.unit === '#HMS') {
        //             newObj.unit = "";
        //             }
        //             // if (typeof newObj.value !== 'number') {
        //             //   if (typeof newObj.value === 'boolean') {
        //             //     if (newObj.value === true) newObj.value = 1;
        //             //     else newObj.value = 0;
        //             //   } else {
        //             //     newObj.value = Number(newObj.value);
        //             //   }
        //             // }
        //             const scaledObj = parseNode(newObj);
                    
        //                         if (typeof scaledObj.value !== 'number') scaledObj.value = Number(scaledObj.value);
        //             this.chartOptions.xaxis.categories.push(entry._time);
        //             this.chartSeries[0].data.push(scaledObj.value);
        //         });
        //         this.valueMin = Math.min(...this.chartSeries[0].data).toFixed(2);
        //         this.valueMax = Math.max(...this.chartSeries[0].data).toFixed(2);
        //         this.valueAvg = this.chartSeries[0].data.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
        //         this.loading = false;
        //         }
        //     });
        // },
        getChart() {
            this.chart24Loading = true;
            this.chart24Timestamp = new Date(Date.now());
            for (let i = 0; i < 10; i++) {
                // this.storeAData.datasets[0].data[i] = this.nodeEntry.value.value;
                this.storeAData.datasets[0].data[i] = this.getModuleValue().value;
            }
            let dateStart = new Date();
            let dateEnd = new Date();
            if (!this.dateTimeSelectEnd) this.dateTimeSelectEnd = new Date(Date.now());
            if (!this.dateTimeSelectStart) this.dateTimeSelectStart = new Date(Date.now() - (24 * 60 * 60 * 1000));
            dateStart.setTime(this.dateTimeSelectStart);
            dateEnd.setTime(this.dateTimeSelectEnd);
            // this.dateTimeFormat = '%d.%m.%Y - %H:';
            this.dateTimeFormat = '%d.%m.%Y - %Hh';
            const request = {
                hash: this.moduleHash,
                startTime: dateStart,
                endTime: dateEnd,
                format: this.dateTimeFormat,
                window: '1h'
                // startTime: dateDayAgo,
                // endTime: dateNow
            }

            SocketioService.getChart(request, (err, response) => {
                if (!err && response !== null) {
                    this.values24 = [];
                    let labels24 = [];
                    for (let i = 0; i < response.length; i++) {
                        this.values24.push(response[i]._value);
                        this.storeBData.labels[i] = response[i].nodeId;
                        this.options.xaxis.categories[i] = response[i].nodeId;
                        labels24.push(response[i].nodeId);
                        this.storeBData.datasets[0].data[i] = response[i]._value;
                        this.series[0].data[i] = response[i]._value;
                        //   this.$refs.storeB.chart.update();
                    }
                    // if (this.$refs.storeB !== null) {
                    //     if (this.$refs.storeB.chart !== undefined) {
                    //         this.$refs.storeB.chart.update();
                    //     }
                    // }
                    this.valueMin = Math.min(...this.values24).toFixed(2);
                    this.valueMax = Math.max(...this.values24).toFixed(2);
                    this.valueAvg = this.values24.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
                    this.chart24Loading = false;
                }
            });
        },
        // getChart() {
        //     this.chart24Loading = true;
        //     this.chart24Timestamp = new Date(Date.now());
        //     for (let i = 0; i < 10; i++) {
        //         // this.storeAData.datasets[0].data[i] = this.nodeEntry.value.value;
        //         this.storeAData.datasets[0].data[i] = this.getModuleValue().value;
        //     }
        //     if (this.nodeEntry.source !== undefined) {
        //         SocketioService.getServerData('getChart', this.nodeEntry.source.path, (err, response) => {
        //             if(response !== null) {
        //                 this.values24 = [];
        //                 let labels24 = [];
        //                 for (let i = 0; i < response.length; i++) {
        //                 this.values24.push(response[i].value);
        //                 this.storeBData.labels[i] = response[i]._id;
        //                 this.options.xaxis.categories[i] = response[i]._id;
        //                 labels24.push(response[i]._id);
        //                 this.storeBData.datasets[0].data[i] = response[i].value;
        //                 this.series[0].data[i] = response[i].value;
        //                 //   this.$refs.storeB.chart.update();
        //                 }
        //                 // if (this.$refs.storeB !== null) {
        //                 //     if (this.$refs.storeB.chart !== undefined) {
        //                 //         this.$refs.storeB.chart.update();
        //                 //     }
        //                 // }
        //                 this.valueMin = Math.min(...this.values24).toFixed(2);
        //                 this.valueMax = Math.max(...this.values24).toFixed(2);
        //                 this.valueAvg = this.values24.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
        //                 this.chart24Loading = false;
        //             }
        //         });
        //     }
        // },
        calculateStore(storeData, totalValue) {
            let randomNumber = +(Math.random() * 500).toFixed(2);
            let data = [...storeData.datasets[0].data];
            let length = data.length;
            data.push(randomNumber);
            data.shift();
            storeData.datasets[0].data = data;

            let diff = +(data[length - 1] - data[length - 2]).toFixed(2);
            let status = diff === 0 ? 0 : diff > 0 ? 1 : -1;
            totalValue = +(totalValue + diff).toFixed(2);

            return { diff, totalValue, status };
        },
        showChart(value) {
            if (value === 'value1') this.showChart1 = true;
        },
        parseValue(val) {
            const value = { ...val };
            if (value.unit === '#BOOL') {
                if (value.value === true) value.value = '🟢Ein';
                else value.value = '🔴Aus';
            }
            return value;
        },
    },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-in-enter-active {
  animation: fadeIn linear 0.3s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}
.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}
.slide-fade-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
.pulse-enter-active {
    animation: pulse 1s;
}
.pulse-leave-active {
    animation: pulse 0.5s;
}
@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9); /* increasing the size */
    }
    30% {
        transform: scale(1.1); /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }
    100% {
        transform: scale(1); /* seeting back to initial size */
        opacity: 1;
    }
}
.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}
.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}
.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}
.box {
    padding: 0.4em !important;
    text-align: center;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}
.box p {
    overflow-wrap: break-word;
    hyphens: auto;
}
.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;;
}
.text-normal {
    line-height: 0;
}
.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}
.card:hover .menu-overlay {
    opacity: 1;
}
.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}
// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}
.list-leave-active {
    animation: list 0.5s;
}
@keyframes list {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


</style>